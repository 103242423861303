#register-form {
    width: 70%;
}

#go-todo {
    width: 100%;
    display: none;
    text-align: center;
}

@media (max-width: 600px) {
    #register-form {
        width: 100%;
    }
}
