#privacyPage p {
    margin-left: 20px;
}

#privacyPage ul {
    margin-left: 40px;
}

#privacy-content {
    width: 100%;
}
