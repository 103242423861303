textarea {
    font-family: 'BIZ UDPGothic', sans-serif;
    border-radius: 5px 5px 5px 5px;
    border: solid 1px #b73cff;
    padding: 10px;
    font-size: 16px;
    line-height: 18px;
    transition: all 0.2s;
}

textarea:focus {
    outline: 0;
    filter: drop-shadow(0px 0px 3px rgba(252, 130, 231, 0.8));
}

input {
    transition: all 0.2s;
}

input[type='text'] {
    border-radius: 5px 5px 5px 5px;
    border: solid 1px #b73cff;
    padding: 10px;
    font-size: 16px;
}

input[type='text']:focus {
    filter: drop-shadow(0px 0px 3px rgba(252, 130, 231, 0.8));
    outline: 0;
}

input[type='password'] {
    border-radius: 5px 5px 5px 5px;
    border: solid 1px #b73cff;
    padding: 10px;
    font-size: 16px;
}

input[type='password']:focus {
    filter: drop-shadow(0px 0px 3px rgba(252, 130, 231, 0.8));
    outline: 0;
}

input[type='email'] {
    border-radius: 5px 5px 5px 5px;
    border: solid 1px #b73cff;
    padding: 10px;
    font-size: 16px;
}

input[type='email']:focus {
    filter: drop-shadow(0px 0px 3px rgba(252, 130, 231, 0.8));
    outline: 0;
}

select {
    border-radius: 5px;
    padding: 5px;
    margin-left: 10px;
}

button {
    height: 40px;
    padding: 5px;
    border-radius: 5px 5px 5px 5px;
    border: solid 1px #b73cff;
    background-color: #b73cff;
    color: #fff;
    transition: all 0.2s;
    font-size: 16px;
}

button:hover {
    background-color: #d48aff;
    filter: drop-shadow(0px 0px 3px rgba(252, 130, 231, 0.8));
}

.button-text {
    vertical-align: 5px;
    margin-right: 5px;
}

a {
    color: #8e39ff;
    transition: all 0.2s;
}

a:hover {
    color: #c981f2;
}

h3 {
    margin: 10px;
}

p {
    line-height: 1.5rem;
}

li {
    line-height: 1.5rem;
}

#header {
    padding: 20px;
    margin-bottom: 20px;
    background-color: #a1a1a1;
    border-radius: 0 0 20px 20px;
    color: #eee;
    filter: drop-shadow(0px 0px 5px rgba(34, 34, 34, 0.8));
}

#header h1 {
    margin-top: 0;
}

#header a {
    color: #353535;
    text-decoration: none;
    transition: all 0.3s;
}

.header-container {
    display: flex;
    justify-content: space-between;
    height: 36px;
}

.header-container div {
    display: flex;
}

.header-container div:first-child {
    justify-content: flex-start;
    align-items: center;
}

.header-container div:last-child {
    justify-content: flex-end;
    align-items: center;
}

.header-logo {
    font-family: 'Orbitron', sans-serif;
    letter-spacing: 5px;
    font-size: 20px;
    font-weight: 600;
}

.header-logo-img {
    height: 20px;
    position: relative;
    top: -30px;
    left: 230px;
}

#header a:hover {
    color: #d899ff;
    filter: drop-shadow(0px 0px 3px rgba(160, 44, 255, 0.8));
}

.header-menu-container {
    justify-content: flex-end;
    align-items: center;
}

#header-menu {
    margin-right: 50px;
}

#logout {
    color: #353535;
    text-decoration: none;
    transition: all 0.3s;
}

#logout:hover {
    color: #d899ff;
    filter: drop-shadow(0px 0px 3px rgba(160, 44, 255, 0.8));
    cursor: pointer;
}

/****************************
 * Service Worker アップデート
 ****************************/
.SW-update-dialog {
    position: relative;
    width: 100%;
    background-color: #b73cff;
    color: #eee;
    filter: drop-shadow(0px 0px 5px rgba(34, 34, 34, 0.8));
    z-index: 10;
}

.SW-update-innner {
    text-align: center;
    padding: 5px;
}

.SW-update-innner button {
    margin-left: 20px;
    background-color: #eee !important;
    color: #7b00c2 !important;
}

.SW-update-innner button:hover {
    background-color: #642c85 !important;
    color: #eee !important;
    filter: drop-shadow(0px 0px 3px rgba(252, 130, 231, 0.8));
}

/****************************
 * ハンバーガーメニューボタン
 ***************************/
.schroll-disable {
    overflow: hidden;
}

.menu-trigger,
.menu-trigger span {
    display: inline-block;
    transition: all 0.4s;
    box-sizing: border-box;
}

#menu-button-container {
    margin-right: 20px;
}

#menu-button {
    display: none;
}

.menu-trigger {
    position: relative;
    width: 25px;
    height: 22px;
    background: none;
    border: none;
    appearance: none;
    cursor: pointer;
}

.menu-trigger span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #353535;
    border-radius: 2px;
}

.menu-trigger span:nth-of-type(1) {
    top: 0;
}

.menu-trigger span:nth-of-type(2) {
    top: 10px;
}

.menu-trigger span:nth-of-type(3) {
    bottom: 0;
}

#menu-button.active span:nth-of-type(1) {
    transform: translateY(10px) rotate(-45deg);
}

#menu-button.active span:nth-of-type(2) {
    left: 50%;
    opacity: 0;
    animation: active-menu-button-bar02 0.8s forwards;
}

@keyframes active-menu-button-bar02 {
    100% {
        height: 0;
    }
}

#menu-button.active span:nth-of-type(3) {
    transform: translateY(-10px) rotate(45deg);
}

/****************************
 * ロード画面
 ***************************/
.load {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #333;
    color: #eee;

    z-index: 999999;
}

.loader,
.loader:before,
.loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
}
.loader {
    color: #ffffff;
    font-size: 10px;
    margin: 80px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
.loader:before,
.loader:after {
    content: '';
    position: absolute;
    top: 0;
}
.loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.loader:after {
    left: 3.5em;
}
@-webkit-keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}
@keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

/****************************
 * コンテンツ
 ***************************/

#layout {
    margin-bottom: 5vh;
}

.container {
    width: 90%;
    margin: 0 auto;
}

.content {
    display: flex;
    flex-wrap: nowrap;
    width: calc(100% - 40px);
    padding: 20px;
    background-color: #eee;
    color: #333;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 2px rgba(34, 34, 34, 0.8));
}

.content-warp {
    flex-wrap: wrap !important;
    justify-content: center;
}

.content-max {
    width: 100%;
}

.inner-content75 {
    width: 75%;
}

.inner-content25 {
    width: 25%;
}

.content-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bold {
    font-weight: 600;
}

.icon-small {
    font-size: 18px;
    vertical-align: -3px;
}

.warning-text {
    color: #d23030;
}

/****************************
 * フォームメッセージ
 ***************************/

.form-message {
    width: calc(100% - 30px);
    padding: 15px;
    margin: 10px 0 10px 0;
    font-weight: 800;
    font-size: 20px;
    border-radius: 5px;

    display: none;
}

.error-message {
    background-color: #ffd3d3;
    color: #d23030;
    border: solid 1px #d23030;
}

.ok-message {
    background-color: #e9fff7;
    color: #2e7a3d;
    border: solid 1px #2e7a3d;
}

@media (max-width: 650px) {
    #menu-button {
        position: relative;
        display: block;
        margin-right: 20px;
    }

    #header-title h1 {
        font-size: 25px !important;
    }

    #helo-logo {
        height: 40px;
        position: relative;
        top: -20px;
        left: 0px;
    }

    #header-menu {
        display: none;
        color: rgba(238, 238, 238, 0);
    }

    .header-search-form {
        display: none;
    }

    .header-full {
        animation-name: slideDown;
        animation-fill-mode: backwards;
        animation-duration: 0.5s;
        animation-timing-function: ease;
        animation-direction: normal;
        height: 100vh;
    }

    @keyframes slideDown {
        0% {
            height: 67px;
        }

        100% {
            height: 100vh;
        }
    }

    .header-menu-active {
        display: block !important;
        position: absolute;
        top: 62px;
        left: 0px;
        width: 100%;
    }

    #header .nav-item {
        display: flex !important;
        margin: 0;
        padding: 10px !important;
        justify-content: center !important;
    }

    .inner-content75 {
        width: 100% !important;
    }

    .inner-content25 {
        width: 100% !important;
    }
}

/****************************
 * フォーム
 ***************************/

.input-group {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
}

.input-group:first-child {
    margin-top: 0;
}

.input-group label {
    display: flex;
    width: 100%;
    align-items: center;
}

.input-group input {
    display: block;
    width: 100%;
    margin: 10px;
}

.center-big-button-container {
    width: 100%;
    text-align: center;
    margin-top: 10px;
}

.center-big-button {
    width: 50%;
    height: 40px;
}

.button-danger {
    background-color: #b62424 !important;
    border: 1px solid #bb1b1b;
    color: #eee;
}

.button-danger:hover {
    background-color: #7e0000 !important;
    color: rgb(255, 255, 255);
    filter: drop-shadow(0px 0px 3px rgba(136, 0, 41, 0.8));
}

.button-outline {
    height: 40px;
    padding: 5px;
    border-radius: 5px 5px 5px 5px;
    border: solid 1px #b73cff;
    background-color: rgba(255, 255, 255, 0);
    color: #b73cff;
    transition: all 0.2s;
    font-size: 16px;
}

/****************************
 * モーダル関係
 ***************************/

.modal {
    position: absolute;
    left: calc(5% - 20px);
    z-index: 99999;
    width: 90%;
    padding: 20px;
    border-radius: 15px;
    background-color: #eee;
    color: #333;
    animation: modalAnimetion;
    animation-fill-mode: backwards;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-direction: normal;

    filter: drop-shadow(0px 0px 5px rgba(34, 34, 34, 0.8));
}

.modal-message {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.modal-control {
    display: flex;
    justify-content: space-around;
}

/****************************
 * ナビゲーション共通
 ***************************/

.nav-item {
    display: inline-flex;
    margin-left: 20px;
    padding: 5px;
}

/****************************
 * ヘッダー外のナビゲーション
 ***************************/

.nav {
    margin: 40px;
}

.nav a {
    color: #eee;
    transition: all 0.2s;
}

.nav a:hover {
    color: #b73cff;
}

/****************************
 * シンボルアニメーション
 ***************************/

 .symbol-animation:hover {
    font-weight: 700;
    transition: all 0.5 ease;
 }