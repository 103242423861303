#login-form-container {
    justify-content: center;
    flex-wrap: wrap;
}

#login-form {
    width: 50%;
}

@media (max-width: 600px) {
    #login-form {
        width: 90%;
    }
}

#login-form-contaner {
    display: flex;
    justify-content: center;
}

#login-bottom-menu {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}
