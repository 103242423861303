#add-modal {
    clear: both;
}

#task-input-textarea {
    float: left;
    resize: none;
    width: calc(100% - 22px);
    border-radius: 5px 5px 0 0 !important;
    border-bottom: 0 !important;
    margin: 0;

    height: 30vh;
}

#open-modal-button {
    width: 100%;
}

#add-button {
    width: 100%;
    float: left;
    margin: 0;
    border-radius: 0 0 5px 5px !important;
    color: #fff;
}

.add-button-active {
    filter: drop-shadow(0px 0px 3px rgba(252, 130, 231, 0.8));
}

@keyframes modalAnimetion {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.mobal-close {
    width: 100%;
    text-align: right;
    margin-bottom: 5px;
}

.mobal-close span {
    cursor: pointer;
    font-size: 32px;
}

.mobal-close span:hover {
    color: #bd1c1c;
    transition: all 0.5s ease;
}
