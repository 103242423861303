@import url('https://fonts.googleapis.com/css2?family=BIZ+UDPGothic&family=Source+Code+Pro&family=Orbitron&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0');

* {
    margin: 0;
    padding: 0;
    -webkit-text-stroke: 0.2px; 
}

body {
    font-family: 'BIZ UDPGothic', sans-serif;
    background-color: #555555;
    color: #eee;
}

#app {
    width: 100%;
}
