.todo {
    display: block !important;
    margin-top: 20px;
    justify-content: space-between;
    transition: background-color ease 0.5s;
    transition: color ease 0.5s;
}

.is-delete {
    animation: fadeOut;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-direction: normal;
}

.is-completed {
    background-color: #3d3d3d !important;
    color: #a7a7a7 !important;
}

.is-completed .doneButton {
    color: #479747;
}

.task-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.task-buttons div {
    display: flex;
    cursor: pointer;
    transition: all 0.2s;
    justify-content: left;
    align-items: center;
}

.done-button,
.edit-button {
    margin-left: 10px;
}

.delete-button {
    width: 24px;
    display: flex;
}

.edit-button:hover {
    color: #d899ff;
}

.done-button:hover {
    color: #479747;
}

.delete-button:hover {
    color: #ff6b6b;
}

.task-content {
    padding: 0 10px 0 10px;
    font-size: 16px;
    line-height: 18px;
    line-break: anywhere;
}

.create-date {
    font-size: 12px;
    margin-top: 40px;
    text-align: right;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.task-comment {
    max-height: 200px;
    overflow-y: scroll;
}

.task-comment-list-open {
    height: 400px;
    animation: comment-open-animetion 0.5s ease-out forwards;
}

.task-comment-list-close {
    height: 0px;
    animation: comment-close-animetion 0.5s ease-out backwards;
}

@keyframes comment-open-animetion {
    0% {
        height: 0px;
    }

    100% {
        height: 400px;
    }
}

@keyframes comment-close-animetion {
    0% {
        height: 400px;
    }

    100% {
        height: 0px;
    }
}
