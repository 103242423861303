.task-list {
    background-color: #929292;
    padding: 10px;
    border-radius: 10px;
}

.cursor-pointer {
    cursor: pointer;
}

.date {
    margin-top: 60px;
    justify-content: center;
    top: 10px;
    filter: drop-shadow(0px 0px 5px rgba(34, 34, 34, 0.8));
}

.date:first-child {
    margin-top: 10px;
}

.todos {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.todo-wrapper {
    width: 100%;
}

.todo-control {
    width: 100%;
    text-align: right;
    margin-top: 20px;
}

.list-name {
    position: relative;
    top: -10px;
    left: -10px;
    width: calc(100%);
    font-weight: 400;
    padding: 10px;
    background-color: #252525;
    border-radius: 10px 10px 0 0;
    text-align: center;
}
